import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const ItemWrapper = styled.div`
  display: grid;
  grid-template-rows: 2fr 1fr;
  gap: 1em;
  text-align: center;

  img {
    align-self: center;
    justify-self: center;
    width: 250px;
    height: 25vh;
  }

  p {
    font-size: 16px;
    align-self: start;
    justify-self: center;
  }

  @media (min-width: 550px) {
    grid-template-rows: 1fr 50px;
  }

  @media (min-width: 1024px) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 4em;

    p {
      align-self: center;
      text-align: left;
    }
  }
`;

const Item = ({ description, imgSrc, imgAlt }) => (
  <ItemWrapper>
    <img src={imgSrc} alt={imgAlt} />
    <p>{description}</p>
  </ItemWrapper>
);

Item.propTypes = {
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.element.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

const SectionWrapper = styled.section`
  display: grid;
  padding: 5em calc(15vw / 2);
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 4em;

  background-color: #00033f;
  background-image: linear-gradient(135deg, #00033f, #1f2058);

  @media (min-width: 550px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 8em 4em;
  }

  @media (min-width: 1024px) {
    gap: 2em 4em;
  }
`;

SectionWrapper.Item = Item;

export default SectionWrapper;
