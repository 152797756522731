import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import ServerStack from "assets/icons/server-stack.svg";
import BreakthroughDiscoveries from "assets/images/breakthrough-discoveries.svg";
import ComplexLegacy from "assets/images/complex-legacy-schema.svg";
import QantevBrain from "assets/images/qantev-brain-technology.svg";
import CreateModels from "assets/images/technology-create-models.svg";
import MetadataInference from "assets/images/technology-metadata-inference.svg";
import NoisyData from "assets/images/technology-noisy-data.svg";
import RefiningDomain from "assets/images/technology-refining-domain.svg";
import CallToAction from "components/CallToAction";
import Card from "components/Card";
import Divider from "components/Divider";
import Hero from "components/Hero";
import PageContainer from "components/PageContainer";
import QantevLink from "components/QantevLink";
import SEO from "components/SEO";
import TechonologyFourItemsGrid from "components/TechnologyFourItemsGrid";

const TechnologyPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        title={t("Cutting edge optimization for the real world")}
        img={
          <Hero.HeroImage
            src={QantevBrain}
            alt="brain"
            loading="lazy"
            className="large"
          />
        }
        divider={
          <Divider icon={<img src={ServerStack} alt="server-stack" />} />
        }
      >
        <p>
          {t(
            "Balance competing priorities while maintaining a birds-eye view over the member portfolio and procurement network with drill-down capabilities to detect inefficiencies' root causes."
          )}
        </p>
      </Hero>
      <Card
        title={t(
          "Applying academic breakthroughs to the health insurance operations"
        )}
        dividerText={t("RESEARCH")}
        imgSrc={BreakthroughDiscoveries}
        imgAlt="Breakthrough discoveries"
      >
        {t(
          "Our team carries out meaningful, cutting-edge research. We apply optimal transport theory and machine learning to create game-changing tools."
        )}
      </Card>
      <Card
        title={t("Addressing complex legacy process")}
        dividerText={t("TECHNOLOGY")}
        imgSrc={ComplexLegacy}
        imgAlt="Complex Legacy"
        alignImgLeft
      >
        {t(
          "We help insurers make better decisions. To achieve this we leverage the experience accumulated by insurance professionals over many years and automate many of the most tedious operations."
        )}
      </Card>
      <TechonologyFourItemsGrid>
        <TechonologyFourItemsGrid.Item
          description={t(
            "Building robust training methods to deal with health data that is noisy and heterogenous."
          )}
          imgSrc={NoisyData}
          imgAlt="noisy data"
        />
        <TechonologyFourItemsGrid.Item
          description={t(
            "Refining domain adaptation techniques to handle differences between geographies and medical care standards."
          )}
          imgSrc={RefiningDomain}
          imgAlt="refining domain"
        />
        <TechonologyFourItemsGrid.Item
          description={t(
            "Combining external data with inferred metadata to assess individual providers' quality of care."
          )}
          imgSrc={MetadataInference}
          imgAlt="meta data inference"
        />
        <TechonologyFourItemsGrid.Item
          description={t(
            "Conditioning learning on structured expert knowledge to create models that are both accountable and interpretable."
          )}
          imgSrc={CreateModels}
          imgAlt="create models"
        />
      </TechonologyFourItemsGrid>
      <section
        style={{
          display: "flex",
          padding: "4em .5em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          textAlign: "center",
          color: "#00033f",
          background: "linear-gradient(180deg, #e5e5e5, #fff)",
        }}
      >
        <h1 style={{ color: "#00033f" }}>{t("Advanced analytics.")}</h1>
        <h1 style={{ color: "#00033f" }}>{t("Actionable insights.")}</h1>
        <h1 style={{ color: "#00033f" }}>
          {t("The future of health insurance operations.")}
        </h1>
        <QantevLink to="/demo" target="_blank" rel="noopener" $btn>
          {t("Request Demo")}
        </QantevLink>
      </section>

      <CallToAction />
    </PageContainer>
  );
};

export default TechnologyPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Technology" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["technology", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
